import numeral from "numeral"
import React, { useState, useEffect } from "react"

export const removeDuplicates = array => {
  const result = array.reduce((acc, current) => {
    const x = acc.find(item => item === current)
    if (!x) {
      return acc.concat([current])
    } else {
      return acc
    }
  }, [])

  return result
}

export const currToNumber = amount => {
  const hasDecimal = amount.includes(".")
  if (hasDecimal) {
    const decimalAmount = numeral(amount).format("0,0.00")
    const result = decimalAmount.split(",").join("")
    console.log("result:", result)
    return result
  }

  const newAmount = numeral(amount).format("0,0")
  return newAmount.split(",").join("")
}
export const thousands_separators = num => {
  var num_parts = num.toString().split(".")
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  return num_parts.join(".")
}
export const formatCurrency = string => {
  if (string) {
    const number = String(string).split(".")
    const decimal = number[1]
    if (decimal)
      if (decimal.length > 1) {
        return numeral(string).format("0,0[.]00")
      } else {
        return numeral(string).format("0,0[.]0")
      }
    else {
      return numeral(string).format("0,0[.]00")
    }
  }
  return string
}
export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value])

  return debouncedValue
}

export const windowDimension = () => {
  const [dimension, setDimension] = useState({ height: 0, width: 0 })
  const { height, width } = dimension
  const [isFirstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    const handleResize = () =>
      setDimension({ width: window.innerWidth, height: window.innerHeight })

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    if (isFirstRender) return setIsFirstRender(false)

    setDimension({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }, [isFirstRender])

  const is476 = width <= 476
  const is700 = width > 476 && width <= 700
  const is1024 = width > 700 && width <= 1024
  const isLarger1024 = width > 1024

  const xs = width < 576
  const sm = width < 768
  const md = width < 992
  const lg = width < 1200
  const xl = width < 1400
  const xxl = width >= 1400

  return {
    is476,
    is700,
    is1024,
    isLarger1024,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    // is1280,
    // is1440,
    height,
    width,
    ...dimension,
  }
}
