import { graphql, navigate, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import styled from "styled-components"
import { windowDimension } from "../../tools/tools"

export const FloatingBanner = ({ isEnglish }) => {
  const { xs } = windowDimension()
  const dataFloatingBanner = useStaticQuery(graphql`
    {
      banner: allStrapiBannerWebIds {
        nodes {
          bannerIdLink
          bannerIdLinkEnglish
          imageId: bannerIdImage {
            childImageSharp {
              fluid(maxWidth: 3080, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageEn: bannerIdImageEnglish {
            childImageSharp {
              fluid(maxWidth: 3080, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageIdRes: bannerIdRes {
            childImageSharp {
              fluid(maxWidth: 3080, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageEnRes: bannerIdResEnglish {
            childImageSharp {
              fluid(maxWidth: 3080, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const [indexActive, setIndexActive] = useState(0)
  const data = dataFloatingBanner?.banner?.nodes

  const isOnlyOneData = data.length === 1

  const ImageLink = isEnglish
    ? data[indexActive]?.bannerIdLinkEnglish
    : data[indexActive]?.bannerIdLink

  const ImageSrc = isEnglish
    ? data[indexActive]?.imageEn?.childImageSharp?.fluid
    : data[indexActive]?.imageId?.childImageSharp?.fluid

  const ImageSrcRes = isEnglish
    ? data[indexActive]?.imageEnRes?.childImageSharp?.fluid
    : data[indexActive]?.imageIdRes?.childImageSharp?.fluid

  useEffect(() => {
    setTimeout(() => {
      if (indexActive === data?.length - 1) {
        setIndexActive(0)
      } else {
        setIndexActive(indexActive + 1)
      }
    }, 2500)
  }, [indexActive])

  return (
    <MainDivFloating>
      <Container>
        <a href={ImageLink} target="_blank">
          <div
            style={{
              // boxShadow: "0 12px 14px -1px rgba(0, 0, 0, 0.1)",
              borderRadius: "0px 0px 15px 15px",
              overflow: "hidden",
            }}
          >
            <ImgStyled
              fluid={xs ? ImageSrcRes : ImageSrc}
              alt="how-it-works"
              draggable={false}
              loading="eager"
              style={{ maxHeight: "100px" }}
            />
            {/* <ImgStyledRes
              fixed={ImageSrcRes}
              alt="how-it-works"
              draggable={false}
              loading="eager"
              style={{ maxHeight: "100px" }}
            /> */}
          </div>
        </a>
        {!isOnlyOneData && (
          <DivDot>
            {(data || [])?.map((_, index) => {
              const isActive = indexActive === index
              return (
                <DotStyled
                  key={index}
                  bgColor={isActive && "#DB64A0"}
                  onClick={() => setIndexActive(index)}
                />
              )
            })}
          </DivDot>
        )}
      </Container>
    </MainDivFloating>
  )
}

const MainDivFloating = styled.div`
  padding-top: 100px;
  margin-bottom: -80px;
  @media (max-width: 768px) {
    padding-top: 130px;
  }
`

const DivDot = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
  gap: 4px;
  cursor: pointer;
`

const DotStyled = styled.div`
  width: 7px;
  height: 7px;
  background-color: ${p => p.bgColor || "#F0F0F0"};
  border-radius: 50%;
`

const ImgStyled = styled(Img)`
  /* max-height: 100px; */
  /* @media (max-width: 576px) {
    display: none;
  } */
`
const ImgStyledRes = styled(Img)`
  width: 100%;
  @media (min-width: 576px) {
    display: none;
  }
`
