import React, { useEffect, useRef, useState } from "react"
import { Container } from "react-bootstrap"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

export const NewsTicker = ({ isEnglish }) => {
  const dataNewsTickerRaw = useStaticQuery(graphql`
    {
      list: allStrapiNewsTickerTransfezComs {
        nodes {
          strapiId
          linkEn
          linkId
          titleEn
          titleId
        }
      }
    }
  `)
  const dataNewsTicker = dataNewsTickerRaw?.list?.nodes

  const dataDummy = [
    {
      titleId: "ini nomor 1",
      titleEn: "ini nomor 1",
    },
    {
      titleId: "ini nomor 2",
      titleEn: "ini nomor 2",
    },
    {
      titleId: "ini nomor 3",
      titleEn: "ini nomor 3",
    },
  ]

  const [activeIndex, setActiveIndex] = useState(0)
  const [activeTicker, setActiveTicker] = useState(dataNewsTicker[0])

  useEffect(() => {
    setTimeout(() => {
      if (activeIndex === dataNewsTicker.length - 1) {
        setActiveIndex(0)
        setActiveTicker(dataNewsTicker[activeIndex])
      } else {
        setActiveIndex(activeIndex + 1)
        setActiveTicker(dataNewsTicker[activeIndex])
      }
    }, 2000)
  }, [activeIndex])

  const { titleId, titleEn, linkId, linkEn } = activeTicker || {}

  const title = isEnglish ? titleEn : titleId
  const link = isEnglish ? linkEn : linkId

  return (
    <MainDiv>
      <Container>
        <SubDiv>
          <MainAnchor href={link} target="_blank">
            <MainTitle>{title}</MainTitle>
          </MainAnchor>
        </SubDiv>
      </Container>
    </MainDiv>
  )
}

const MainDiv = styled.div`
  position: fixed;
  z-index: 20;
  top: 80px;
  /* height: 35px; */
  padding: 6px 0px;
  background-color: #da649f;
  transition: all 200ms ease;
  width: 100%;
  box-shadow: ${p => p.bshadow || "0 12px 14px -1px rgba(0, 0, 0, 0.2)"};
`

const SubDiv = styled.div`
  /* height: 35px; */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.7s;
`

const MainTitle = styled.p`
  transition: all 0.7s;
  font-family: "Typefez-bold";
  color: white;
  font-size: 14px;
  margin-bottom: 0px;
  @media (max-width: 576px){
    text-align: center;
  }
`

const MainAnchor = styled.a`
  :hover {
    color: white;
  }
`
