import { Link } from "gatsby"
import React, { useState } from "react"
import Logo from "../../images/logo-jack.svg"
import styled from "styled-components"
import { Button, Container } from "react-bootstrap"

export const ToggleBusiness = () => {
  const [toggle, setToggle] = useState(false)

  return (
    <MainDiv>
      {!toggle ? (
        <>
          <CustomToggleDiv zindex="2">PERSONAL</CustomToggleDiv>
          <AnchorStyled href="https://neo.transfez.com/">
            <CustomToggleDiv
              pleft="10px"
              width="145px"
              mleft="-30px"
              zindex="1"
              bshadow="none"
              bcolor="#E6E6E6"
              color="#DA649F"
              ffamily="Typefez-reg"
              onClick={() => setToggle(false)}
            >
              BUSINESS
            </CustomToggleDiv>
          </AnchorStyled>
        </>
      ) : (
        <>
          <CustomToggleDiv
            pright="10px"
            width="145px"
            mright="-30px"
            zindex="1"
            bshadow="none"
            bcolor="#E6E6E6"
            color="#DA649F"
            ffamily="Typefez-reg"
            onClick={() => NavigateClick()}
          >
            PERSONAL
          </CustomToggleDiv>
          <CustomToggleDiv zindex="2">BUSINESS</CustomToggleDiv>
        </>
      )}
    </MainDiv>
  )
}

export const ToggleJack = ({ isEnglish, floating }) => {
  const text = isEnglish
    ? "For business solution visit Jack"
    : "Untuk solusi bisnis kunjungi Jack"

  const onClick = () => window.open("https://www.itsjack.com/", "_blank")

  return (
    <MainDivJack
      displayDesktop={floating ? "none" : "flex"}
      displayMobile={floating ? "flex" : "none"}
      jcontentMobile={floating && "space-between"}
    >
      <MainText>{text}</MainText>
      <LogoJack src={Logo} alt="logo" />
      <MainButtonJack onClick={onClick}>
        <MainText>itsjack.com</MainText>
      </MainButtonJack>
    </MainDivJack>
  )
}

const MainDiv = styled.div`
  margin-left: 100px;
  display: flex;
  position: relative;
  @media (max-width: 992px) {
    margin-left: 30px;
    transform: scale(0.7);
  }
  @media (max-width: 768px) {
    display: none;
  }
`

const AnchorStyled = styled.a`
  :hover {
    text-decoration: none;
  }
`

const CustomToggleDiv = styled.div`
  transition: ease-in-out 150ms;
  cursor: pointer;
  z-index: ${p => p.zindex};
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: ${p => p.pright};
  padding-left: ${p => p.pleft};
  margin-right: ${p => p.mright};
  margin-left: ${p => p.mleft};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.bcolor || "#DA649F"};
  width: ${p => p.width || "135px"};
  text-align: center;
  box-shadow: ${p => p.bshadow || "0px 2.5px 10px rgba(0, 0, 0, 0.25)"};
  border-radius: 25px;
  color: ${p => p.color || "white"};
  font-family: ${p => p.ffamily || "Typefez-bold"};
  font-size: 14px;
`

export const ContainerJackFloating = styled(Container)`
  background-color: white !important;
  padding-top: 6px;
  padding-bottom: 6px;
  box-shadow: 2px 7px 14px -10px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: 2px 7px 14px -10px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 2px 7px 14px -10px rgba(0, 0, 0, 0.51);

  @media (min-width: 768px) {
    display: none;
  }
`

const MainDivJack = styled.div`
  display: ${p => p.displayDesktop || "flex"};
  align-items: center;
  gap: 12px;

  @media (max-width: 768px) {
    display: ${p => p.displayMobile || "none"};
    margin-bottom: ${p => p.mbMobile};
    justify-content: ${p => p.jcontentMobile};
  }
`

const MainText = styled.h5`
  font-size: 14px;
  font-family: "Typefez-med";
  margin-bottom: 0px;

  @media (max-width: 992px) {
    text-align: end;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    white-space: nowrap;
  }
  @media (max-width: 414px) {
    font-size: 10px;
  }
  @media (max-width: 347px) {
    font-size: 8px;
  }
`

const LogoJack = styled.img`
  @media (max-width: 347px) {
    width: 48px;
  }
`

const MainButtonJack = styled(Button)`
  background-color: black;
  color: white;
  padding: 8px 12px;
  border-radius: 8px;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;

  :hover,
  :focus,
  :active {
    background-color: black;
  }
`
