import React, { useState } from "react"
import { Container } from "react-bootstrap"
import styled from "styled-components"
// import Logo from "../../images/transfez-by-jack.png"
import Logo from "../../images/transfez-new-logo-navbar.png"
import { CustomButton, OutlineButton } from "../Buttons/parent"
import { ToggleLanguage } from "./toggle"
import { window } from "browser-monads"
import { Link, useLocation } from "@reach/router"
import {
  ContainerJackFloating,
  ToggleBusiness,
  ToggleJack,
} from "./toggleBusiness"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { SidebarNav } from "./sidebar"
import { NewsTicker } from "./newsTicker"
import { FloatingBanner } from "./FloatingBanner"

export const NavigationBar = ({
  showToggleBusiness = false,
  showBanner = false,
}) => {
  const [navbarTop, setNavbarTop] = useState(true)
  const [sidebarActive, setSidebarActive] = useState(false)
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const isSg = location.pathname.includes("/sg")

  const changeBackground = () => {
    if (window.scrollY >= 20) {
      setNavbarTop(false)
    } else {
      setNavbarTop(true)
    }
  }

  const LinkLogoDecider = () => {
    if (isSg) {
      return isEnglish ? "/en/sg/" : "/sg/"
    }
    return isEnglish ? "/en/" : "/"
  }

  window.addEventListener("scroll", changeBackground)
  const textRegister = isEnglish ? "Register" : "Daftar"
  const textSignIn = isEnglish ? "Sign in" : "Masuk"
  const downloadLink = isEnglish ? "/en/download/" : "/download/"
  const linkDecider = LinkLogoDecider()

  const dataResponsive = [
    ...(showToggleBusiness
      ? [
          {
            title: "Business",
            navigate: "https://neo.transfez.com/",
            external: true,
          },
        ]
      : []),
    { title: "Download", navigate: isEnglish ? "/en/download" : "/download" },
  ]

  return (
    <>
      {showBanner && <FloatingBanner isEnglish={isEnglish} />}
      <MainDiv bshadow={navbarTop && "none"}>
        <ContainerStyled>
          <LeftDiv>
            <Link to={linkDecider}>
              <LogoImg src={Logo} alt="logo" />
            </Link>
          </LeftDiv>
          <RightDiv
          // scale={!showToggleBusiness && "0.9"}
          // mright={showToggleBusiness && "-15px"}
          // mleft={!showToggleBusiness && "-10px"}
          >
            {/* <ToggleLanguage isNavbarOnTop={navbarTop} isRegional /> */}
            {/* {showToggleBusiness && <ToggleJack isEnglish={isEnglish} />} */}
            <ToggleLanguage isNavbarOnTop={navbarTop} />
            <ButtonDiv>
              <DownloadDiv>
                <Link to={downloadLink}>
                  <CustomButton>Download</CustomButton>
                </Link>
              </DownloadDiv>
              {/* {showToggleBusiness && ( */}
              <HamburgerIcon
                icon={faBars}
                color="#db64a0"
                cursor="pointer"
                onClick={() => setSidebarActive(true)}
              />
              {/* )} */}
              {/* <CustomButton>{textRegister}</CustomButton>
            <OutlineButton>{textSignIn}</OutlineButton> */}
            </ButtonDiv>
          </RightDiv>
          {/* {sidebarActive && ( */}
          {/* {showToggleBusiness && ( */}
          <SidebarNav
            isOpen={sidebarActive}
            setIsOpen={() => setSidebarActive(false)}
            data={dataResponsive}
          />
          {/* )} */}
        </ContainerStyled>
        {/* <ContainerJackFloating>
          <ToggleJack isEnglish={isEnglish} floating />
        </ContainerJackFloating> */}
      </MainDiv>
      {/* <NewsTicker isEnglish={isEnglish} /> */}
    </>
  )
}

const MainDiv = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  height: 80px;
  padding: 18px 0px;
  background-color: white;
  transition: all 200ms ease;
  width: 100%;
  box-shadow: ${p => p.bshadow || "0 12px 14px -1px rgba(0, 0, 0, 0.1)"};
`

const ContainerStyled = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  margin-top: -18px;
`

const LeftDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const RightDiv = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 16px;
  @media (max-width: 576px) {
    transform: scale(${p => p.scale || "1"});
    margin-right: ${p => p.mright};
    margin-left: ${p => p.mleft};
  }
`

const ButtonDiv = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const LogoImg = styled.img`
  max-width: 180px;
  min-width: 180px;
  @media (max-width: 576px) {
    max-width: 150px;
    min-width: 150px;
  }
`

const SideNav = styled.div`
  height: 100%;
  width: 300px;
  margin-left: 200px;
  position: fixed;
  top: 0;
  right: 0%;
  background-color: red;
  overflow-x: hidden;
  transition: all 100ms ease-in-out;
  padding: 60px;
  opacity: ${p => p.opacity};
  visibility: ${p => p.vis};
`

const HamburgerIcon = styled(FontAwesomeIcon)`
  @media (min-width: 768px) {
    display: none;
  }
`

const DownloadDiv = styled.div`
  @media (max-width: 768px) {
    display: ${p => p.display || "none"};
  }
`
