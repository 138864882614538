import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"

export const SidebarNav = ({ isOpen, setIsOpen, data = [] }) => {
  return (
    <SideNav opacity={isOpen ? "1" : "0"} vis={isOpen ? "visible" : "hidden"}>
      <div className="w-100 text-right">
        <FontAwesomeIcon
          icon={faTimes}
          color="white"
          cursor="pointer"
          size="2x"
          style={{ marginBottom: 20 }}
          onClick={setIsOpen}
        />
        {data?.map((item, index) => {
          const { title, external, navigate } = item || {}
          return (
            <>
              {external ? (
                <a href={navigate} target="_blank" key={index}>
                  <NavItem>{title}</NavItem>
                </a>
              ) : (
                <Link to={navigate} key={index}>
                  <NavItem>{title}</NavItem>
                </Link>
              )}
            </>
          )
        })}
      </div>
    </SideNav>
  )
}

const SideNav = styled.div`
  height: 100vh;
  width: 300px;
  margin-left: 200px;
  position: fixed;
  top: 0;   
  right: 0%;
  background-color: #102c57;
  overflow-x: hidden;
  transition: all 100ms ease-in-out;
  padding: 40px;
  z-index: 99;
  opacity: ${p => p.opacity};
  visibility: ${p => p.vis};
`

const NavItem = styled.h3`
  width: 100%;
  color: white;
  font-family: "Typefez-med";
  :hover {
    text-decoration: underline !important;
  }
`
