import { useLocation } from "@reach/router"
import React, { useRef, useEffect, useState } from "react"
import { Form, Nav } from "react-bootstrap"
import styled from "styled-components"
import dropdownIcon from '../../images/dropdown-icon.svg'
import { Navigate } from "./navigation"
import { navigate } from "gatsby"

export const ToggleLanguage = ({ isRegional = false }) => {
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const [language, setLanguage] = useState("")
  const [openModal, setOpenModal] = useState(false)

  const textLanguage = isEnglish ? "EN" : "ID"
  const textLabel = isRegional ? "Regional" : textLanguage
  const data = isRegional ? ["Indonesia", "Singapore"] : ["ID", "EN"]

  const handleChange = event => {
    setLanguage(event)
    const kemana = Navigate(event, location)
    navigate(kemana)
  }

  const toggleModal = () => setOpenModal(p => !p)

  const handleNavbar = value => {
    const isIndonesia = value === "Indonesia"
    const isSingapore = value === "Singapore"
    setOpenModal(false)
    if (isRegional) {
      if (isIndonesia) navigate(isEnglish ? "/en/" : "/")
      if (isSingapore) navigate(isEnglish ? "/en/sg/" : "/sg/")
      return
    }
    handleChange(value)
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenModal(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref])
  }
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <div className="d-flex align-items-center" style={{ position: "relative" }}>
      <DivLabel onClick={toggleModal}>
        <FontLabel>{textLabel}</FontLabel>
        <img src={dropdownIcon} alt="icon" className="ml-2" />
      </DivLabel>
      {openModal && (
        <ModalTooltip ref={wrapperRef} width={isRegional && "120px"}>
          {data.map((item, i) => {
            return (
              <FontModal key={i} onClick={() => handleNavbar(item)}>
                {item}
              </FontModal>
            )
          })}
        </ModalTooltip>
      )}
    </div>
  )
}

const ModalTooltip = styled.div`
  position: absolute;
  z-index: 100;
  top: -10px;
  left: -5px;
  width: ${p => p.width || "60px"};
  background-color: white;
  border: 1px solid #db64a0;
  box-shadow: 0px 10px 30px rgba(15, 44, 87, 0.12);
`
const DivLabel = styled.div`
  display: flex;
  cursor: pointer;
`
const FontLabel = styled.h5`
  color: #db64a0;
  font-size: 16px;
  margin-bottom: 0;
  font-family: "Typefez-extrabold";
`

const FontModal = styled.h5`
  color: #db64a0;
  background-color: white;
  font-size: 16px;
  margin-bottom: 0;
  padding: 8px;
  font-family: "Typefez-extrabold";
  text-align: center;
  cursor: pointer;
  :hover {
    color: white;
    background-color: #db64a0;
  }
`
